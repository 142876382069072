.App {
  padding: 0;
}

.Header {
  border-bottom: 1px solid #ddd;
}

.Sidebar {
  width: 120px;
  margin: 5px;
  position: sticky;
  top: 62px;
  align-self: flex-start;
  align-items: start;
}

.Sidebar .nav-item {
  width: 100%;
  margin: 5px;
}

.Sidebar a, .dropdown-item, .dropdown-item.active {
  color: #444;
  font-size: 20px;
}

.Sidebar .nav-item .active .dropdown-item.active {
  background-color: #def;
}

.Sidebar a:hover {
  background-color: #eee;
}

.Sidebar a:visited {
  color: #444;
}

.Content {
  margin-top: 10px;
  overflow: auto;
}

.Post {
  align-items: start;
  padding-top: 5px;
  border-bottom: 1px solid #eee
}

.Post p {
  padding-left: 10px;
}

.Post:hover {
  background-color: #f8f8f8;
}

.Post a {
  color: #14c;
  text-decoration: none;
}

.Post a:visited {
  color: #14c;
}

.More {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.InputField {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Write {
  margin-bottom: 10px;
  padding: 30px 0px 40px 0px;
  border-bottom: 1px solid #eee;
}

.HeaderLink, .HeaderLink:hover, .HeaderLink:visited {
  color: #444;
  text-decoration: none;
}

.Policy {
  margin: 5px;
  position: sticky;
  align-self: flex-start;
  align-items: start;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-left: 3px solid #33f370;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  max-width: 100%;
  display: block;
}

.container {
  max-width: 1320px;
}

.form-login.container {
  width: 75%;
  max-width: 440px;
  padding: 15px;
  margin: 0 auto;
}